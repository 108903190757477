import { post } from '@/utils/request'

// 机构列表
export function businessList(data) {
    return post('/admin/family/businessList', data)
}

// 审核详情
export function businessInfo(data) {
    return post('/admin/family/businessInfo', data)
}


// 机构重新启用
export function familyPassStart(data) {
    return post('/admin/business/familyPassStart', data)
}